import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'
export default function useCampaignUserList() {
    const toast = useToast();
    const refListTable = ref(null);
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'email', label: 'Email', sortable: false },
        { key: 'created_by', label: 'By', sortable: false },
        { key: 'status',label:'Status', sortable: false },
        
    ];
    const campaign_id = router.currentRoute.params.id;
    const perPage = ref(50);
    const groupQuery = ref('');
    const totals = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totals.value,
            }
    });
    const refetchData = () => {
    
        refListTable.value.refresh()
    };
   
   
    watch([ currentPage, perPage, searchQuery,groupQuery], () => {
      
        refetchData()
    })
    const fetchData = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);
   
        const userData = JSON.parse(localStorage.getItem('userData'));
        console.log('campaign_id',campaign_id);
        store
        .dispatch('app-crm/fetchcampaignUserData', {
            perpage: perPage.value,
            offset:offset,
            campaign_id:campaign_id,
            keyword:searchQuery.value
         
        }).then(response => {
           
            
            const data = response.data.data;
            
            totals.value = response.data.total
            console.log('response-crm',data);
            callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch((err) => {
           
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching "+err,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
    }
    return {
        refListTable,
        tableColumns,
        perPage,
        groupQuery,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        fetchData
    }
}